@font-face {
  font-family: "DIN";
  src: local("DIN"),
    url("./fonts/DinTypeface/DIN-Light.ttf") format("truetype");
}

@font-face {
  font-family: "DIN";
  src: local("DIN"),
    url("./fonts/DinTypeface/DIN-Medium.ttf") format("truetype");
  font-weight: bold;
}

body {
  font-family: "DIN" !important;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #C8A895 !important;
  /* background-color:red !important; */

}

h2 {
  padding-top: 50px;
  text-transform: uppercase;

  /* margin-bottom: 25px !important; */
  font-weight: bold !important;
}

img[src*='#left'] {
  float: left;
  margin-left: 10px;
  margin-top: 20px;
}

@media only screen and (max-width: 450px) {
  h2 {
    font-weight: normal !important;
    text-transform: uppercase;
    margin-bottom: 100px !important;
    margin-left: -20px !important;
    padding-top: 60px !important;
    color: rgb(59, 58, 58);
    font-size: 50px !important;
    letter-spacing: 2px;
    color: white;
    height: 30px;
    ;
  }

  img[src*='#left'] {
    width: 70px;
  }
}

a {
  color: black !important;
  font-size: 18px !important;
}

.header {
  width: 100%;
  height: 144px;
  background-repeat: repeat-x;
}

.header.home {
  background-color: rgba(37, 37, 42, 1);
}

.header.dark-mode {
  animation-name: dark-mode-animation;
  animation-duration: 1s;
  background-color: rgba(68, 68, 68, 0.9);
  background-blend-mode: multiply;
}

@keyframes dark-mode-animation {
  from {
    background-color: rgba(68, 68, 68, 0)
  }

  to {
    background-color: rgb(68, 68, 68, 0.9)
  }
}

.background-image.muriel {
  width: 100%;
  object-fit: fill;
}

.each-fade>div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 550px;
}

.each-fade span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}

.film-title {
  letter-spacing: 3px;
  font-size: 20px;
}

h2.film-title {
  font-size: 30px;
}

.film-item {
  width: 100%;
  color: rgb(48, 24, 24);
}

@media only screen and (max-width: 450px) {

  .film-list-container {
    margin-top: 20px;
  }

  .film-item {
    font-size: 14px;
    color: rgb(48, 24, 24);
    padding: 5px 0px 0px 0px;
  }
}

a {
  font-size: 1.2em;
}

.page-container a {
  text-decoration: underline;
}

p {
  font-size: 1.2em;
}

.header-text {
  font-size: 50px;
  color: rgba(0, 0, 0, 0.5);
}


.homeImage {
  position: fixed;
  bottom: 0;
  right: 0;
  top: 0; 
  left: 0; 
  z-index: -100;
  background-image: linear-gradient(to bottom,  rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0)), url("./assets/images/mobile/bg/refrigerastor.jpg");
  background-size: contain;
  min-width: 100%;
  min-height: 100%;
  background-position: 40% 60%;
}

.homeImage2 {
  z-index: -100;
  background-image: linear-gradient(to bottom,  rgba(0, 0, 0, 1), rgba(0, 0, 0, 1),rgba(0, 0, 0, 1));
  min-width: 300%;
  min-height: 100%;
  object-fit:fill;
}

@media only screen and (max-width: 450px) {
  .content-header {
    padding: 50px 30px 0px 10%;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .trailer-dark-mode-mobile {
    animation-name: dark-mode-animation;
    animation-duration: 1s;
    background-color: rgba(68, 68, 68, 0.9);
    background-blend-mode: multiply;
  }

  h2.film-title {
    color: white;
    font-size: 30px !important;
    margin-top: -10px !important;
  }

  .header-film {
    width: 100vw;
    height: 200px;
    padding: 0px;
    left: 0px;
    position: absolute;
    z-index: -100;
    padding-left: 40px;
    margin-top: -50px;
  }

  .generic-content {
    margin-top: 120px !important;
  }
}

.generic-content a {
  text-decoration: underline;
}

@media (orientation: landscape) and (max-width: 900px) {
  .content-header {
    background-image: none !important;
    padding: 0px 60px 0px 90px;
  }

  .header-film {
    background-image: none !important;
  }

  #main-header {
    height: 30px;
    background-image: none !important;
  }
}

#video-background {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: fill;  
  transform: translate(-50%, -50%);  
  z-index: -100;  
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  #video-background {
      /* Adjustments for iPad portrait mode */
      object-fit: cover;  
  }
}

#video-background-mobile {
  position: fixed;
  right: -520px;
  bottom: 0;
  width: 100% !important;
  height: auto !important;
  z-index: -100;
}

/* for some reason html5 video control are still showing -  hide them! */
.rh5v-DefaultPlayer_controls {
  display: none !important;
}

.navbar-nav.home a {

  color: rgb(255, 247, 247) !important;
}

.logo.home {
  color: rgb(255, 247, 247) !important;
  font-size: 20px;
}

.navbar-inner {
  background: rgba(0, 0, 0, 0.7) !important;
}

.navbar-brand {
  font-size: medium !important;
}

.social-icon {
  padding-right: 15px;
}

@media only screen and (max-width: 450px) {
  .social-icon img {
    width: 65px;
    height: 65px;
  }
}


.contact-me {
  margin-top: 20px;
}

@media only screen and (max-width: 450px) {
  .contact-me {
    text-align: center;
  }
}

.back-to-film {
  margin: 30px 0px 0px 0px;
}

.back-to-films-text {
  color: #211f18d5;
  font-weight: bold;
  padding-top: 25px;
}

.tab-list {
  border: none !important;
}


.my-tab {
  font-size: 14px;
  padding: 20px 0px 0px 0px;

}

.nav-tabs .nav-link {
  background-color: #2e3138 !important;
  color: rgb(255, 255, 255) !important;
  border: none !important;
  margin-right: 1px;
}

.nav-tabs .nav-link.active {
  background-color: #cbc8c8 !important;
  color: rgb(0, 0, 0) !important;
  font-weight: bold;
  border: none !important;
}

/* Styles for screens with width below 390px */
@media only screen and (max-width: 390px) {
  .nav-item button {
    padding: 10px;
    margin-left: 0px;
    margin-top: 105px;
    height: 45px;
    font-size: 10px;
  }


  .tab-list {
    border: none !important;
  }

  .my-tab {
    padding: 15px 30px 0px 10px;
  }

  .nav-tabs .nav-link {
    background-color: #2e3138 !important;
    color: white !important;
    border-bottom: none !important;
  }

  .nav-tabs .nav-link.active {
    background-color: #cbc8c8 !important;
    color: black !important;
    border-bottom: none !important;
  }

  .back-to-film {
    margin: 20px 0px 0px 10px;
  }
}

@media only screen and (min-width: 390px) and (max-width: 450px) {
  .nav-item button {
    padding: 10px;
    margin-left: 0px;
    margin-top: 105px;
    height: 45px;
    font-size: 12px;
  }

  .tab-list {
    border: none !important;
  }

  .my-tab {
    padding: 15px 30px 0px 10px;
  }

  .nav-tabs .nav-link {
    background-color: #2e3138 !important;
    color: white !important;
    border-bottom: none !important;
  }

  .nav-tabs .nav-link.active {
    background-color: #cbc8c8 !important;
    color: black !important;
    border-bottom: none !important;
  }

  .back-to-film {
    margin: 20px 0px 0px 10px;
  }

}

@media only screen and (max-width: 450px) {
  .my-trailer {
    width: 100% !important;
    height: 200px !important;

  }

  .player-control-container {
    margin-left: 10px;
    padding-bottom: 5px;
  }

  .player-icon {
    width: 120px !important;
    margin: 10px 0px 10px 0px;
    fill: white;
  }

  .trailers {
    margin-top: 30px;
    background-color: black;
  }
}

.player-control-container {
  width: 90%;
}

.player-icon {
  width: 30px;
  margin: 10px 0px 10px 0px;
}

.player-icon:hover {
  cursor: pointer;
}

.back-up:hover {
  cursor: pointer;
}

.footer {
  padding-top: 20px;
  clear:both
}